var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showEndOfStageClientFeedbackTool
    ? _c(
        "div",
        { staticClass: "feedback-tool" },
        [
          _vm.clientSavedFeedback
            ? _c("client-feedback-thank-you", {
                attrs: { "show-thank-you": _vm.showThankYou },
              })
            : _vm.processedClientFeedback
            ? _c("div", { staticClass: "feedback-container" }, [
                _c("div", { staticClass: "initial-interaction-container" }, [
                  _c("span", { staticClass: "prompt" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.text.userPrompt) + "\n      "
                    ),
                  ]),
                  _c("div", { staticClass: "options-container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "option thumbs-up",
                        class: {
                          selected: _vm.clientFeedbackChoice === "thumbs-up",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleOptionClick("thumbs-up")
                          },
                        },
                      },
                      [
                        _c("font-awesome-svg", {
                          staticClass: "icon",
                          attrs: {
                            type: "thumbs-up",
                            fill: _vm.getFillColor("thumbs-up"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "option-divider" }),
                    _c(
                      "div",
                      {
                        staticClass: "option thumbs-down",
                        class: {
                          selected: _vm.clientFeedbackChoice === "thumbs-down",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleOptionClick("thumbs-down")
                          },
                        },
                      },
                      [
                        _c("font-awesome-svg", {
                          staticClass: "icon",
                          attrs: {
                            type: "thumbs-down",
                            fill: _vm.getFillColor("thumbs-down"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm.clientFeedbackChoice !== ""
                  ? _c(
                      "div",
                      [
                        _c("feedback-response-section", {
                          attrs: { "source-category": _vm.sourceCategory },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          !_vm.processedClientFeedback ? _c("ct-centered-spinner") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }